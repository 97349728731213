const menuText = document.querySelector('#mobile_menu span');
const menuIcon = document.querySelector('.i-menu');
const closeIcon = document.querySelector('.i-close');
const { body } = document;

export function toggleMobileMenu(e) {
    e.preventDefault();

    const isOpen = body.classList.contains('mobile-menu--visible');

    if (isOpen) {
        // menu is open so close it
        menuText.textContent = 'Menu';
        menuIcon.classList.remove('u-hide');
        closeIcon.classList.add('u-hide');
        body.classList.remove('mobile-menu--visible');
        body.classList.add('mobile-menu--hidden');
    } else {
        menuText.textContent = '';
        menuIcon.classList.add('u-hide');
        closeIcon.classList.remove('u-hide');
        body.classList.add('mobile-menu--visible');
        body.classList.remove('mobile-menu--hidden');
    }
}

function init() {
    /* Menu Show/Hide */
    const menuToggle = document.getElementById('mobile_menu');
    /**
     * Only display mobile init when there is the top nav menu
     * Avoid 'Campaign page' error because these pages don't have the top nav
     */
    if (menuToggle) {
        menuToggle.addEventListener('click', toggleMobileMenu);
    }
}

export default {
    init,
};
