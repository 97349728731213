import Reoako from '@octavenz/reoako';

const reoakoAnalyticsAdapter = (eventType, detail) => {
    const category = 'Reoako';
    const action = eventType;
    let label = '';
    if (typeof detail === 'string') {
        label = detail;
    } else if (detail.hasOwnProperty('mi')) {
        label = detail.mi;
    } else if (detail.hasOwnProperty('en')) {
        label = detail.en;
    }
    // console.log({ category, action, label });
    if (window.ga && typeof window.ga === 'function') {
        window.ga('send', {
            hitType: 'event',
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
        });
    }
};

export function init() {
    const apiDomainEl = document.querySelector('[name=reoako-api-domain]');
    const apiKeyEl = document.querySelector('[name=reoako-api-key]');
    let apiKey = null;
    let apiDomain = null;
    if (apiKeyEl && apiDomainEl) {
        apiKey = apiKeyEl.getAttribute('content');
        apiDomain = apiDomainEl.getAttribute('content');
    }
    if (apiKey && apiDomain) {
        Reoako.init({
            apiDomain, // defaults to prod
            apiKey,
            track: reoakoAnalyticsAdapter,
        });
    }
}

export default { init };

