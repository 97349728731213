function YoutubeAnalytics() {

    const self = this;

    //enable the JavaScript API for an embedded player
    for (var e = document.getElementsByTagName("iframe"), x = e.length; x--;)
        if (/youtube.com\/embed/.test(e[x].src))
            if(e[x].src.indexOf('enablejsapi=') === -1)
                e[x].src += (e[x].src.indexOf('?') ===-1 ? '?':'&') + 'enablejsapi=1';

    self.gtmYTListeners = []; // support multiple players on the same page

    // attach our YT listener once the API is loaded
    window.onYouTubeIframeAPIReady = function() {
        for (var e = document.getElementsByTagName("iframe"), x = e.length; x--;) {
            if (/youtube.com\/embed/.test(e[x].src)) {
                self.gtmYTListeners.push(new YT.Player(e[x], {
                    events: {
                        onStateChange: self.onPlayerStateChange.bind(self),
                        onError: self.onPlayerError.bind(self)
                    }
                }));
            }
        }
    }

    // load the Youtube JS api and get going
    var j = document.createElement("script"),
        f = document.getElementsByTagName("script")[0];
    j.src = "//www.youtube.com/iframe_api";
    j.async = true;
    f.parentNode.insertBefore(j, f);

};

YoutubeAnalytics.prototype = {

    // listen for play, pause and end states
    // also report % played every second
    onPlayerStateChange: function(e) {
        e["data"] == YT.PlayerState.PLAYING && setTimeout(this.onPlayerPercent.bind(this), 1000, e["target"]);
        var video_data = e.target && e.target["getVideoData"](),
            label = video_data.title;
        // Get title of the current page
        var pageTitle = document.title;
        if (e["data"] == YT.PlayerState.PLAYING && (YT.gaLastAction == undefined || YT.gaLastAction == "p")) {
            label = "Video Played - " + video_data.title;
            this.trackEvent({
                'event': 'youtube',
                'eventCategory': 'Youtube Videos',
                'eventAction': pageTitle,
                'eventLabel': label
            });
            YT.gaLastAction = "";
        }
        if (e["data"] == YT.PlayerState.PAUSED) {
            label = "Video Paused - " + video_data.title;
            this.trackEvent({
                'event': 'youtube',
                'eventCategory': 'Youtube Videos',
                'eventAction': pageTitle,
                'eventLabel': label
            });
            YT.gaLastAction = "p";
        }
    },

    // catch all to report errors through the GTM data layer
    // once the error is exposed to GTM, it can be tracked in UA as an event!
    onPlayerError: function(e) {
        this.trackEvent({
            'event': 'error',
            'eventCategory': 'Youtube Videos',
            'eventAction': 'GTM',
            'eventLabel': "youtube:" + e["target"]["src"] + "-" + e["data"]
        })
    },

    // report the % played if it matches 0%, 25%, 50%, 75% or completed
    onPlayerPercent: function(e) {
        if (e["getPlayerState"]() == YT.PlayerState.PLAYING) {
            var t = e["getDuration"]() - e["getCurrentTime"]() <= 1.5 ? 1 : (Math.floor(e["getCurrentTime"]() / e["getDuration"]() * 4) / 4).toFixed(2);
            if (!e["lastP"] || t > e["lastP"]) {
                var video_data = e["getVideoData"](),
                    label = video_data.title;
                // Get title of the current page
                var pageTitle = document.title;
                e["lastP"] = t;
                label = t * 100 + "% Video played - " + video_data.title;
                this.trackEvent({
                    'event': 'youtube',
                    'eventCategory': 'Youtube Videos',
                    'eventAction': pageTitle,
                    'eventLabel': label
                })
            }
            e["lastP"] != 1 && setTimeout(this.onPlayerPercent.bind(this), 1000, e);
        }
    },

    trackEvent: function(e) {
        if (typeof window.ga === "function") {
            window.ga('send', 'event', e.eventCategory, e.eventAction, e.eventLabel);
        }
    },

};

function init() {
    return new YoutubeAnalytics();
}

export default {
    init,
};

