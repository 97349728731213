function init() {
    let childSelectorDropdown = $('[data-tagged-child-selector-dropdown]');
    if (childSelectorDropdown.length) {

        let taggedChildItems = $('[data-tagged-child-page-item]');

        childSelectorDropdown.on('change', function(e) {
            let selectedOption = e.currentTarget.value;
            if (selectedOption.length === 0) {
                // Default option selected so show everything
                taggedChildItems.show();
            } else {
                taggedChildItems.hide();
                taggedChildItems.filter(function() {

                    // Remove first part of id
                    let elementId = this.id.replace(/^key\d*\|\|\|/, "");

                    // Split the tags into an array
                    let tags = elementId.split("~~~");
                    return tags.includes(selectedOption);

                }).show();
            }
        })

        // Run a change on page load to ensure the right children are showing. This is important
        // for when the back button is used in the browser and the select dropdown retains its previous state.
        childSelectorDropdown.change();
    }
}

// module.exports = { init: init };
export default { init: init }
