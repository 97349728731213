import $ from 'jquery';
import isValid from 'date-fns/isValid';
import parse from 'date-fns/parse';
import subYears from 'date-fns/subYears';
import compareAsc from 'date-fns/compareAsc';
// var moment = require('moment');

function pad(n){return n<10 ? '0'+n : n}

function checkStopConditions(currentField) {

  if (currentField) {
    let formSubmitButton = $('#form-page-form input[type="submit"]');
    let currentFieldName = currentField.attr('name');
    window.stopConditions.forEach(function(condition) {

        let actionField = $(`[name='${condition.field_name}']`);

        if (currentFieldName === actionField.attr('name')) {
          let actionFieldParentContainer = actionField.parents('[data-waf-field]').first();
          let actionFieldValue = undefined;
          if (actionField.is(':checkbox') || actionField.is(':radio')) {
              actionFieldValue = $(`[name='${condition.field_name}']:checked`).val();
          } else {
              actionFieldValue = actionField.val();
          }

          for (let i = 0; i < condition.stop_conditions.length; i++) {
            let cond = condition.stop_conditions[i];
              let fieldStopConditionContainer = $(`#${condition.field_name}_stop_condition`);
              let value = cond.value;
              if (actionFieldValue !== undefined && value === actionFieldValue) {
                  fieldStopConditionContainer.html(cond.stop_message);
                  fieldStopConditionContainer.show();
                  actionFieldParentContainer.nextAll('[data-waf-field]').hide();
                  formSubmitButton.attr('disabled', 'disabled');
                  break;
              } else {
                  fieldStopConditionContainer.hide();
                  actionFieldParentContainer.nextAll('[data-waf-field]').show();
                  let visibleStopNotifications = $('[data-waf-stop]:visible').first();
                  if (visibleStopNotifications.length) {
                    visibleStopNotifications.parents('[data-waf-field]').first().nextAll('[data-waf-field]').hide();
                  } else {
                    formSubmitButton.removeAttr('disabled', 'disabled');
                  }
              }
          }

        }

    });

  }

}

function checkConditions() {

      window.formConditions.forEach(function(condition) {

        let action = condition.action;
        let actionField = $(`[name='${condition.field_name}'],  #html-field-${condition.field_name}, [name^='${condition.field_name}_day'], [name^='${condition.field_name}_month'], [name^='${condition.field_name}_year']`);
        let actionFieldParent = actionField.parents('.waf-fieldset, .html-field-wrapper').first();
        let actionFieldType = condition.field_type;
        let actionFieldRequired = actionFieldType === 'checkboxes' ? false : condition.required;


        let allConditionsPassed = true;

        condition.conditions.forEach(function(cond) {

          let rule = cond.rule;
          let value = cond.value;
          let condField = $(`[name='${cond.field_name}']`);
          let isDateField = false;
          let dateFieldBlank = false;
          let valueDate = null;
          let condFieldValueDate = null;

          if (!condField.length) {
            // Check for date fields
            condField = $(`[name^='${cond.field_name}_day'], [name^='${cond.field_name}_month'], [name^='${cond.field_name}_year']`);
            if (condField.length === 3) {
              isDateField = true;
            }
          }

          let condFieldValue = '';

          if (condField.length > 1) {

            condField.each(function() {

              let field = $(this);
              if (field.is(':checkbox') || field.is(':radio')) {
                if (field.is(':checked')) {
                  condFieldValue += field.val() + ','
                }
              } else if (isDateField) {
                let parsedValue = parseInt(field.val(), 10);
                if (!isNaN(parsedValue)) {
                  condFieldValue += pad(parsedValue, 10) + '/';
                } else {
                  dateFieldBlank = true;
                }
              }

            });

            if (dateFieldBlank) {
              condFieldValue = '';
            } else {

              condFieldValue = condFieldValue.replace(/[,\/]\s*$/, "");

              if (isDateField) {
                // valueDate = moment(value, 'DD/MM/YYYY');
                // condFieldValueDate = moment(condFieldValue, 'DD/MM/YYYY');
              }

            }

          } else {

            condFieldValue = condField.val();

            if (condField.is(':checkbox')) {
              if (condField.is(':checked')) {
                condFieldValue = 'on';
              } else {
                condFieldValue = '';
              }
            }
          }

          if (Array.isArray(condFieldValue)) {
            condFieldValue = condFieldValue.join();
          }

          switch(rule) {
            case 'is':
              if (condFieldValue !== value) {
                allConditionsPassed = false;
              }
              break;
            case 'is_not':
              if (condFieldValue === value) {
                allConditionsPassed = false;
              }
              break;
            case 'is_blank':
              if (condFieldValue !== '') {
                allConditionsPassed = false;
              }
              break;
            case 'is_not_blank':
              if (condFieldValue === '') {
                allConditionsPassed = false;
              }
              break;
            case 'greater_than':
              if (isDateField) {
                if (condFieldValueDate.diff(valueDate) <= 0) {
                  allConditionsPassed = false;
                }

              } else {
                let conditionFieldValue = parseFloat(condFieldValue);
                let parsedValue = parseFloat(value);
                if (isNaN(conditionFieldValue) || conditionFieldValue <= parsedValue) {
                  allConditionsPassed = false;
                }
              }
              break;
            case 'greater_than_equal':
              if (isDateField) {

                if (condFieldValueDate.diff(valueDate) < 0) {
                  allConditionsPassed = false;
                }

              } else {
                let conditionFieldValue = parseFloat(condFieldValue);
                let parsedValue = parseFloat(value);
                if (isNaN(conditionFieldValue) || conditionFieldValue < parsedValue) {
                  allConditionsPassed = false;
                }
              }

              break;
            case 'less_than':
              if (isDateField) {

                if (condFieldValueDate.diff(valueDate) >= 0) {
                  allConditionsPassed = false;
                }

              } else {
                let conditionFieldValue = parseFloat(condFieldValue);
                let parsedValue = parseFloat(value);
                if (isNaN(conditionFieldValue) || conditionFieldValue >= parsedValue) {
                  allConditionsPassed = false;
                }
              }

              break;
            case 'less_than_equal':
              if (isDateField) {

                if (condFieldValueDate.diff(valueDate) > 0) {
                  allConditionsPassed = false;
                }

              } else {
                let conditionFieldValue = parseFloat(condFieldValue);
                let parsedValue = parseFloat(value);
                if (isNaN(conditionFieldValue) || conditionFieldValue > parsedValue) {
                  allConditionsPassed = false;
                }
              }

              break;
            case 'contains':
              if (!condFieldValue.includes(value)) {
                allConditionsPassed = false;
              }
              break;
            case 'starts-with':
              if (!condFieldValue.trim().startsWith(value)) {
                allConditionsPassed = false;
              }
              break;
            case 'ends-with':
              if (!condFieldValue.trim().endsWith(value)) {
                allConditionsPassed = false;
              }
              break;
          }

        });

        if (allConditionsPassed) {
          if (action === 'show') {
            actionFieldParent.show();
            if (actionFieldRequired) {
              actionField.attr('required', true);
            }
          } else {
            actionFieldParent.hide();
            actionField.attr('required', false);
          }
        } else {
          if (action === 'show') {
            actionFieldParent.hide();
            actionField.attr('required', false);

          } else {
            actionFieldParent.show();
            if (actionFieldRequired) {
              actionField.attr('required', true);
            }
          }
        }

      });

}

function scrollToFirstError() {
    const firstError = $('.error').first();
    if (firstError.length) {
      firstError[0].scrollIntoView();
    }
}

function isValidEmail(email) {

    const mailRegExp = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return mailRegExp.test(email);

}

function isValidPhone(phone) {
    const phoneRegExp = /^[\d \-()+]{6,40}$/;
    return phoneRegExp.test(phone);
}

function isOldEnough(dateField, parsedDate) {

  // Check for minimum and maximum age on field
  let minimumAge = dateField.attr('data-minimum-age');
  if (minimumAge) {
      let minimumDate = subYears(new Date(), parseInt(minimumAge, 10));
      if (compareAsc(minimumDate, parsedDate) === -1) {
        return false;
      }
  }

  return true;
}

function isYoungEnough(dateField, parsedDate) {
  let maximumAge = dateField.attr('data-maximum-age');
  if (maximumAge) {
      let maximumDate = subYears(new Date(), parseInt(maximumAge, 10));
      if (compareAsc(parsedDate, maximumDate) === -1) {
        return false;
      }
  }
  return true;
}

function formIsValid() {

  $('[data-waf-field]').removeClass('error');
  $('[data-js-error]').remove();

  let hasErrors = false;
  let requiredFields = $('input,textarea,select').filter('[required]:visible');
  let checkedRadiosAndCheckboxes = [];
  requiredFields.each(function() {
      let requiredField = $(this);

      if (requiredField.is(':checkbox') || requiredField.is(':radio')) {
          if (!checkedRadiosAndCheckboxes.includes(requiredField.attr('name'))) {
              checkedRadiosAndCheckboxes.push(requiredField.attr('name'));
              let choiceField = $(`input[name="${requiredField.attr('name')}"]:checked`);
              if (!choiceField.length) {
                hasErrors = true;
                requiredField.parents('[data-waf-field]').addClass('error');
                requiredField.parents('[data-waf-field]').append('<p class="waf-help-text waf-error-message" data-js-error style="margin-bottom: 0">Please select an option</p>');
              }
          }
      } else {
        if (requiredField.val() === '') {
          hasErrors = true;
          requiredField.parents('[data-waf-field]').addClass('error');
          requiredField.after('<p class="waf-help-text waf-error-message" data-js-error style="margin-bottom: 0">This field is required</p>');
        }
      }
  });

  let emailFields = $('input[type="email"]');

  emailFields.each(function() {
      let emailField = $(this);
      let emailFieldContainer = emailField.parents('[data-waf-field]');
      if (emailField.val() !== '') {
        if (!isValidEmail(emailField.val())) {
          hasErrors = true;
          emailFieldContainer.addClass('error');
          emailField.after('<p class="waf-help-text waf-error-message" data-js-error style="margin-bottom: 0">Please enter a valid email address</p>');
        }
      }
  });

  let phoneFields = $('[data-phone-field]');
  phoneFields.each(function() {
      let phoneField = $(this);
      let phoneFieldContainer = phoneField.parents('[data-waf-field]');
      if (phoneField.val() !== '') {
          if (!isValidPhone(phoneField.val())) {
            hasErrors = true;
            phoneFieldContainer.addClass('error');
            phoneField.after('<p class="waf-help-text waf-error-message" data-js-error style="margin-bottom: 0">Please enter a valid phone number e.g. 021 099 99110</p>');
          }
      }
  });

  let dateFields = $('[data-simple-date-field]');
  dateFields.each(function() {
    let dateField = $(this);
    let dateFieldContainer = dateField.parents('[data-waf-field]');
    let dateFieldHelpText = dateFieldContainer.find('.waf-help-text');
    dateFieldHelpText.removeClass('waf-error-message');
    let dateValue = dateField.val();

    if (dateValue) {
      if (dateValue.length !== 10) {
          hasErrors = true;
          dateFieldContainer.addClass('error');
          dateFieldHelpText.addClass('waf-error-message');
      } else {
        let parsedDate = parse(dateValue, 'dd/MM/yyyy', new Date());
        if (!isValid(parsedDate)) {
          hasErrors = true;
          dateFieldContainer.addClass('error');
          dateFieldHelpText.addClass('waf-error-message');
        }
        if (!isOldEnough(dateField, parsedDate)) {
          hasErrors = true;
          dateFieldContainer.addClass('error');
          dateField.after(`<p class="waf-help-text waf-error-message" data-js-error style="margin-bottom: 0">You must be at least ${dateField.attr('data-minimum-age')} years old</p>`);
        }
        if (!isYoungEnough(dateField, parsedDate)) {
          hasErrors = true;
          dateFieldContainer.addClass('error');
          dateField.after(`<p class="waf-help-text waf-error-message" data-js-error style="margin-bottom: 0">You must be younger than ${dateField.attr('data-maximum-age')} years old</p>`);
        }
      }
    } else {
        if (dateField.prop('required')) {
            hasErrors = true;
            dateFieldContainer.addClass('error');
            dateFieldHelpText.addClass('waf-error-message');
        }
    }
  });

  if (hasErrors) {
    scrollToFirstError();
    return false;
  }

  return true;

}

export function init() {
    if (window.formConditions && window.stopConditions) {

      window.formConditions.forEach(function(condition) {
        if (condition.action === 'show') {
          $(`[name='${condition.field_name}'], #html-field-${condition.field_name}, [name^='${condition.field_name}_day']`).parents('.waf-fieldset').hide();
          $(`[name='${condition.field_name}']`).attr('required', false);
        }
      });

      checkConditions();

      let $pageForm = $('form#form-page-form');

      $pageForm.show();

      $('#form-page-form input, #form-page-form select, #form-page-form textarea').on('change keyup', function() {
        checkConditions();
      });

      $('#form-page-form input, #form-page-form select').on('change keyup', function() {
        let $this = $(this);
        if ($this.is(':checkbox') || $this.is(':radio')) {
          $this.parents('[data-waf-field]').removeClass('error');
          $this.parents('[data-waf-field]').find('[data-js-error]').remove();
        }

          checkStopConditions($this);
      });

      scrollToFirstError();

      let formSubmitButton = $('#form-page-form input[type="submit"]');
      $pageForm.on('submit', function(e) {
          e.preventDefault();
          formSubmitButton.attr('disabled', 'disabled');
          if (!formIsValid()) {
            formSubmitButton.removeAttr('disabled', 'disabled');
            e.preventDefault();
          } else {
            // Let Recaptcha handle the form submission
            const captchaSubmissionEvent = new Event('submitWithCaptcha');
            $(this)[0].dispatchEvent(captchaSubmissionEvent);
          }
      });

    }

}

export default { init };
