
import { querySelectArray }     from '../utils';


function Form(el, el_name) {

    this.el = el;
    this.el_name = el_name

    //grab the fieldsets (steps)
    this.steps = querySelectArray('[data-fieldset]');

    this.currentStep = 0;

    // scope to form
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.start = this.start.bind(this);
    this.triggerSubmit = this.triggerSubmit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onHashChange = this.onHashChange.bind(this);

    // Select buttons
    this.startButtons = querySelectArray('[data-start]');

    this.submitButton = this.el.querySelector('[data-submit]');
    if (!this.startButtons || !this.submitButton) {
        return;
    }
    this.nextButtons = querySelectArray('[data-next]');
    this.prevButtons = querySelectArray('[data-previous]');
    this.mobileMenu = document.querySelector('#mobile_menu');

    // add event listeners
    this.nextButtons.forEach(function(el) {
        el.addEventListener("click", this.nextStep)
    }, this);

    this.prevButtons.forEach(function(el) {
        el.addEventListener("click", this.prevStep)
    }, this);

    this.startButtons.forEach(function(el) {
        el.addEventListener("click", this.start);
    }, this);

    this.submitButton.addEventListener("click", this.triggerSubmit);

    $(".reveal-if-active").hide()
    $("input[name='discontinued_apprentice']").change(function() {
        //console.log("value:", $(this).attr('value'));
        if ($(this).attr('value') == "True") {
            //console.log("hello");
            $(".reveal-if-active").show()
        } else {
            //console.log("partner");
            $(".reveal-if-active").hide()
        }
    });

    // to refactor if time
    var field = $('#id_date_available');

    var MyDate = new Date();
    var MyDateString = '';
    MyDate.setDate(MyDate.getDate());
    var tempoMonth = (MyDate.getMonth() + 1);
    var tempoDate = (MyDate.getDate());
    if (tempoMonth < 10) tempoMonth = '0' + tempoMonth;
    if (tempoDate < 10) tempoDate = '0' + tempoDate;
    MyDateString = tempoDate + '/' + tempoMonth + '/' + MyDate.getFullYear();

    // add today's date to the specific field
    field.val(MyDateString);

    window.addEventListener("hashchange", this.onHashChange, false);

    // fix mobile menu behaviour
    this.scrollTo = this.scrollTo.bind(this);
    this.mobileMenu.addEventListener("click", this.scrollTo, false);
}

Form.prototype = {

    scrollTo: function(e) {
        e.preventDefault();
        const href = this.mobileMenu.getAttribute('href');
        $('html, body').animate({
            scrollTop: $(href).offset().top
        }, 500);
    },

    onHashChange: function(e) {
        var hash = document.location.hash;

        if (hash.match("^\#step-")) {
            const stepNumber = parseInt(hash.replace("#step-", ""), 10);

            // Convert from human readable number to array index.
            const newStep = stepNumber - 1;

            if (newStep > -1) {
                this.hideStartScreen();

                // Fire virtualpages for Google Tag Manager
                if (typeof dataLayer !== 'undefined') {
                    dataLayer.push({
                        'event':'sendVirtualPageview',
                        'vpv':'/register/step-' + (stepNumber - 1) + '-complete/'
                    });
                }

                // Jump to next one
                return this.showStep(newStep);
            }
        }

        // If no step, just show the start screen again and force the
        // viewport back to the top.
        this.showStartScreen();
        document.location.hash = "#";
    },

    hideStartScreen: function() {
        $("[data-hide-on-start]").hide();
        $("[data-show-on-start]").show();
        // $(".profile-form__intro").addClass('hidden-form-intro');
        // $(".profile-form__intro-desc").hide();
        // $("#update-details-p").hide();
        // $(this.startButton).hide();
    },

    showStartScreen: function() {
        var currentEl = this.steps[this.currentStep];

        $("[data-hide-on-start]").show();
        $("[data-show-on-start]").hide();
        // $(".profile-form__intro").removeClass('hidden-form-intro');
        // $(".profile-form__intro-desc").show();
        // $("#update-details-p").show();

        if (currentEl) {
            $(currentEl).removeClass('active');
        }

        // $(this.startButton).show();
    },

    start: function() {
        this.hideStartScreen();
        this.validateStep(0, false);
    },

    validateStep: function(newStep, shouldValidate, shouldSubmitToServer) {
        var validateForm = true;
        var currentEl = this.steps[this.currentStep];

        //check fieldset before continuing
        if (shouldValidate) {
            validateForm = this.validateFieldset(currentEl);

            //errors in form so scroll to the top to
            $("body, html").scrollTop(0)
        } else {
            return this.showStep(newStep);
        }

        if (validateForm) {
            //post data to server
            var data = $(this.el).serializeArray();

            // Only validate, don't create records
            data.push({ name: "validate_only", value: true });

            // wait for results
            $.post("", data, function(data, status, xhr) {
                this.handleSubmit(data, status, xhr, currentEl, newStep, shouldSubmitToServer);
            }.bind(this));
        }
    },

    showStep: function(newStep) {

        var currentEl = this.steps[this.currentStep];
        var el = this.steps[newStep];
        $(currentEl).removeClass('active');
        $(el).addClass('active');

        this.currentStep = newStep;
        this.updateHash(newStep);

        if (this.el_name == 'RegistrationForm') {
            var nexus_pixel_image = document.getElementById('app_nexus_pixel');
            var insights_pixel_image = document.getElementById('m_insights_pixel');

            var nexus_id = window['app_nexus_pixel_id_step' + (this.currentStep + 1).toString()];
            var nexus_seg = window['app_nexus_pixel_segment_step' + (this.currentStep + 1).toString()];
            var insights_id = window['m_insights_pixel_id_step' + (this.currentStep + 1).toString()];

            if (nexus_pixel_image){
                nexus_pixel_image.src = 'https://secure.adnxs.com/px?id=' + nexus_id + 'seg='+ nexus_seg + '&t=2';
            }

            if (insights_pixel_image){
                insights_pixel_image.src = "https://nz-gmtdmp.mookie1.com/t/v2/activity?tagid=" + insights_id + "&src.rand=[timestamp]";
            }
        }

        //scroll to top of next/prev fiedset
        $("body, html").scrollTop(0);
        return newStep;
    },

    updateHash: function(newStep) {
        document.location.hash = "step-" + (+newStep + 1);
    },

    triggerSubmit: function(e) {
        e.preventDefault();

        // If they haven't filled out the previous steps, send them back!
        for(var i = 0; i < this.steps.length; i++)
        {
            var currentEl = this.steps[i];

            if (this.validateFieldset(currentEl) == false) {
                return this.validateStep(i, false)
            }
        }
        return this.validateStep(this.steps.length - 1, true, true);
    },

    nextStep: function(e) {
        e.preventDefault();
        var newStep = this.currentStep + 1;

        if (newStep < this.steps.length) {
            return this.validateStep(newStep, true);
        }
    },

    prevStep: function(e) {
        e.preventDefault();
        var newStep = this.currentStep - 1;
        if (newStep >= 0) {
            return this.validateStep(newStep, false);
        }
    },

    handleSubmit: function(data, status, xhr, currentEl, newStep, shouldSubmitToServer) {
        var hasErrors = false;
        if ("status" in data && data.status == "registered") {
            // TODO Laura nees to make this cleaner
            $("#create-work-profile").replaceWith("<p>" + data.message + "</p>");
        }
        else {
            for (var key in data) {
                var obj = data[key];

                var field = currentEl.querySelector("[name=" + key + "]");
                if (field) {
                    $(field).parent().addClass("error").parents("[data-fieldset]").addClass('fieldset-error');
                    $(field).parent().append("<p class='error-msg'>" + obj[0] + "</p>");
                    hasErrors = true
                }
            }

            if (!hasErrors) {
                if (shouldSubmitToServer) {
                    $(this.el).submit();
                } else {
                    return this.showStep(newStep);
                }
            }
        }
    },

    validateFieldset: function(fieldset) {
        //check fieldset has no errors before going through to the second step
        var validated = true;

        //resetting errors
        $(fieldset).removeClass("fieldset-error");

        $("[data-validate]", fieldset).each(function() {

            var $wrap = $(this),
                $field = $wrap.find("input, select, textarea"),
                type = $wrap.data("validate"),
                value = $field.val();

            //reset error classes
            $wrap.removeClass("error");

            $(".error-msg", $wrap).hide();

            if (type == "required") {

                if ($.trim(value) == "") {
                    $wrap.addClass("error").parents("[data-fieldset]").addClass("fieldset-error");

                    $wrap.append("<p class='error-msg'>We need this info to help find you the right job.</p>");
                    validated = false;
                }

            } else if (type == "checked") {

                var checked = false;

                $field.each(function() {
                    if ($(this).is(':checked')) {
                        checked = true
                    }
                });
                if (!checked) {
                    $wrap.addClass("error").parents("[data-fieldset]").addClass("fieldset-error");
                    $wrap.append("<p class='error-msg'>Please select an option</p>");
                    validated = false;
                }

            } else if (type == "email") {

                var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if (!re.test(value)) {
                    $wrap.addClass("error").parents("[data-fieldset]").addClass("fieldset-error");
                    $wrap.append("<p class='error-msg'>Something's not quite right here.</p>");
                    validated = false;
                }

            } else if (type == "date") {

                var re = /^(0?[1-9]|[12][0-9]|3[01])[\/\-\.](0?[1-9]|1[012])[\/\-\.]\d{2,4}$/;

                if (!re.test(value)) {

                    $wrap.addClass("error").parents("[data-fieldset]").addClass("fieldset-error");
                    $wrap.append("<p class='error-msg'>Check you've used right format &#8211; eg 21/02/1989 for 21 Feb 1989.</p>");
                    validated = false;
                }

            } else if (type == "phone") {
                // Note that it doesn't have any special rules for how many
                // digits, or what numbers are valid in those digits, it just
                // verifies that only digits, parenthesis, dashes, plus, space,
                // pound, asterisk, period, comma, or the letters e, x, t are present.
                var re = /^[0-9+\(\)#\.\s\/ext-]+$/;

                if (!re.test($.trim(value))) {
                    $wrap.addClass("error").parents("[data-fieldset]").addClass("fieldset-error");
                    $wrap.append("<p class='error-msg'>We need this info to help find you the right job.</p>");
                    validated = false;
                }
            }

        });

        return validated;
    }
}

function init() {
    var registrationForm = document.querySelector("#create-work-profile");
    if (registrationForm) {
            return new Form(registrationForm, 'RegistrationForm');
        }

        var courseFinder = document.querySelector("#course-finder");
        if (courseFinder) {
            return new Form(courseFinder, 'CourseFinder');
        }
}

export default {
    init,
};

